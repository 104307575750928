
import OfferCard from '@/components/offer_card'
import PageNavbar from '@/components/page_navbar'
import LoadingSpinner from '@/components/loading_spinner'

export default {
  components: {
    OfferCard,
    LoadingSpinner
  }

  mounted: ->
    @querystring = @$router.currentRoute.query.search || ''
    if @querystring.length > 0
      @on_search()
    else
      @$refs['search-input'].focus()
    window.addEventListener('scroll', @on_scroll)
    @$store.commit('layout/page_nav', {title: @$t('search_page_header'), back_target: '/'})

  beforeDestroy: ->
    window.removeEventListener('scroll', @on_scroll)

  data: -> {
    error: false
    loading: false
    next_page: 1
    results: []
    querystring: ''
    queried: ''
  }

  methods: {
    on_search: (event) ->
      @error = false
      @queried = @querystring
      @next_page = 1
      console.log 'on_search', @next_page
      @$router.push({ query: { search: @querystring } }).catch (error) ->
        return if String(error).includes('Avoided redundant navigation to current location')
        console.error 'search push catch:', error
      @loading = true
      @$store.dispatch('analytics/search', @querystring)
      @$store.dispatch('offers/search', { q: @querystring, page: 1 })
        .then (response) =>
          @$refs['scroll-container'].scrollTop = 0
          @results = response.offers
          @next_page = response.pagination.next_page
          @$refs['search-input'].blur() if @results.length > 0
        .catch (error) =>
          @error = true
          console.error 'search catch:', error
        .finally => @loading = false

    on_scroll: (e) ->
      scrollPosition = window.scrollY
      windowHeight = window.innerHeight
      documentHeight = document.documentElement.scrollHeight
      return unless (documentHeight - (scrollPosition + windowHeight) < 200)
      return if @loading
      return if @next_page == null

      @loading = true
      @$store.dispatch('analytics/search', @querystring)
      @$store.dispatch('offers/search', { q: @querystring, page: @next_page })
        .then (response) =>
          @results = @results.concat(response.offers)
          @next_page = response.pagination.next_page
        .catch (error) =>
          @error = true
          console.error 'search catch:', error
        .finally => @loading = false


  }

}
